<template>
  <div v-if="form.type === 'typeform'">
    <h4 class="mb-4">
      Typeform Configuration
    </h4>
    <v-row class="mt-3">
      <v-col :cols="forms.length > 0 ? 6 : 12">
        <CustomDropdown
          v-if="forms.length > 0"
          v-model="typeformRules.formId"
          header="Typeform Form"
          placeholder="Select a Typeform form"
          required
          :items="forms"
          item-value="id"
          item-text="name"
          @change="onFormChange"
        />
        <v-alert
          v-if="forms.length === 0"
          color="#f8e8ad"
          icon="mdi-alert"
        >
          There are no forms created for the workspace configured for the Typeform plugin. Please create a form in Typeform first or ensure that the correct workspace is configured.
        </v-alert>
      </v-col>
      <v-col
        v-if="form.id && configuredFormIsNotLoaded"
        cols="12"
      >
        <v-alert
          type="error"
        >
          The form originally configured for this form cannot be loaded. It is possible that the form has been
          deleted from the Typeform platform accidentally or the plugin has been configured with a different Typeform
          workspace. Please re-configure the plugin to re-connect this form
          to Typeform.
        </v-alert>
      </v-col>
      <v-col
        v-if="form.id && !isWebhookActive"
        cols="12"
      >
        <v-alert
          color="#f8e8ad"
          icon="mdi-alert"
        >
          There is no webhook enabled for this form. It is possible the webhook was removed from the Typeform platform accidentally.
          In order to re-create this webhook in Typeform, please re-save this form.
        </v-alert>
      </v-col>
      <v-col
        v-if="loadedForm"
        cols="12"
      >
        <div>
          To configure the form, you must map the fields that Pulse Health normally expects in form submissions to the forms in the Typeform Form selected.
        </div>
        <div class="typeform-field-mapping-table">
          <v-simple-table
            dense
          >
            <thead>
              <tr>
                <th>Pulse Health Field</th>
                <th>Typeform Field</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(mapping, i) in typeformRules.fieldMapping"
                :key="'field-mapping-' + i"
              >
                <td>
                  <CustomDropdown
                    v-model="mapping.pulseHealthField"
                    :items="getAvailablePulseHealthFields(mapping.pulseHealthField)"
                    placeholder="Select a Pulse Health field"
                    required
                    autocomplete
                  />
                </td>
                <td class="users-table__header">
                  <CustomDropdown
                    v-model="mapping.typeformField"
                    :items="typeformFields"
                    placeholder="Select a Typeform field"
                    required
                    autocomplete
                    item-value="id"
                    item-text="title"
                  />
                </td>
                <td class="v-menu-attach-parent">
                  <CustomCancelButton
                    class="ml-3"
                    @click="deleteFieldMapping(i)"
                  />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div
          v-if="!allPulseHealthFieldsSelected"
          class="mt-3 mb-5"
        >
          <div
            class="simple-text-button button--blue d-inline-block"
            @click="addFieldMapping"
          >
            <v-icon color="#2b84eb" size="16" class="mr-0">
              mdi-plus
            </v-icon>
            Add Field
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomCancelButton from "@/sharedComponents/CustomCancelButton";

export default {
  name: "EditTypeformConfiguration",
  components: {
    CustomDropdown,
    CustomCancelButton,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      forms: [],
      typeformRules: {
        formId: null,
        fieldMapping: [
          { pulseHealthField: 'salutation', typeformField: null },
          { pulseHealthField: 'firstName', typeformField: null },
          { pulseHealthField: 'middleName', typeformField: null },
          { pulseHealthField: 'lastName', typeformField: null },
          { pulseHealthField: 'credentials', typeformField: null },
          { pulseHealthField: 'gender', typeformField: null },
          { pulseHealthField: 'npi', typeformField: null },
          { pulseHealthField: 'specialty', typeformField: null },
          { pulseHealthField: 'contactType', typeformField: null },
          { pulseHealthField: 'emailAddress', typeformField: null },
          { pulseHealthField: 'emailIsSubscribed', typeformField: null },
          { pulseHealthField: 'emailIsDefault', typeformField: null },
          { pulseHealthField: 'company', typeformField: null },
          { pulseHealthField: 'addressLine1', typeformField: null },
          { pulseHealthField: 'addressLine2', typeformField: null },
          { pulseHealthField: 'city', typeformField: null },
          { pulseHealthField: 'state', typeformField: null },
          { pulseHealthField: 'postalCode', typeformField: null },
          { pulseHealthField: 'country', typeformField: null },
          { pulseHealthField: 'locationIsDefault', typeformField: null },
          { pulseHealthField: 'phone', typeformField: null },
          { pulseHealthField: 'phoneIsCallSubscribed', typeformField: null },
          { pulseHealthField: 'phoneIsCallDefault', typeformField: null },
          { pulseHealthField: 'phoneIsSmsSubscribed', typeformField: null },
          { pulseHealthField: 'phoneIsSmsDefault', typeformField: null },
        ],
      },
      configuredFormIsNotLoaded: false,
      loadedForm: null,
      loadedWebhook: null,
      corePulseHealthFields: [
        { label: 'Salutation', value: 'salutation' },
        { label: 'First Name', value: 'firstName' },
        { label: 'Middle Name', value: 'middleName' },
        { label: 'Last Name', value: 'lastName' },
        { label: 'Credentials', value: 'credentials' },
        { label: 'Gender', value: 'gender' },
        { label: 'NPI', value: 'npi' },
        { label: 'Specialty', value: 'specialty' },
        { label: 'Contact Type', value: 'contactType' },
        { label: 'Email Address', value: 'emailAddress' },
        { label: 'Email Is Subscribed', value: 'emailIsSubscribed' },
        { label: 'Email Is Default', value: 'emailIsDefault' },
        { label: 'Company', value: 'company' },
        { label: 'Address Line 1', value: 'addressLine1' },
        { label: 'Address Line 2', value: 'addressLine2' },
        { label: 'City', value: 'city' },
        { label: 'State', value: 'state' },
        { label: 'Postal Code', value: 'postalCode' },
        { label: 'Country', value: 'country' },
        { label: 'Location Is Default', value: 'locationIsDefault' },
        { label: 'Phone Number', value: 'phone' },
        { label: 'Phone Is Call Subscribed', value: 'phoneIsCallSubscribed' },
        { label: 'Phone Is Call Default', value: 'phoneIsCallDefault' },
        { label: 'Phone Is SMS Subscribed', value: 'phoneIsSmsSubscribed' },
        { label: 'Phone Is SMS Default', value: 'phoneIsSmsDefault' },
      ],
    };
  },
  computed: {
    pulseHealthFields() {
      const customFields = this.$store.getters['user/account']?.customFieldDefinition;
      if (!customFields?.contact) {
        return this.corePulseHealthFields;
      }

      const customFieldValues = [];
      customFields.contact.forEach(field => {
        customFieldValues.push({
          label: 'Custom Field - ' + field.name,
          value: 'contact.customFields.' + field.fieldName,
        });
      });

      return [
        ...this.corePulseHealthFields,
        ...customFieldValues,
      ];
    },
    isWebhookActive() {
      return this.loadedWebhook !== null && !Array.isArray(this.loadedWebhook);
    },
    allPulseHealthFieldsSelected() {
      return this.selectedPulseHealthFields.length === this.pulseHealthFields.length;
    },
    selectedPulseHealthFields() {
      return this.typeformRules.fieldMapping
        .map(mapping => mapping.pulseHealthField)
        .filter(field => field !== null);
    },
    typeformFields() {
      if (!this.loadedForm) {
        return [];
      }

      let fields = [];

      this.loadedForm?.fields.forEach(field => {
        if (field.type === 'statement') {
          return;
        }

        if (field?.properties?.fields) {
          fields = fields.concat(...field?.properties?.fields.map(f => ({id: f.id, title: f.title})));
        } else {
          fields.push({
            id: field.id,
            title: field.title,
          })
        }
      });
      this.loadedForm?.hidden?.forEach(field => {
        fields.push({
          id: field,
          title: 'Hidden Field - ' + field,
        });
      });

      return fields;
    },
  },
  watch: {
    form: {
      async handler() {
        if (!this.loadedForm) {
          await this.loadPageDetails();
        }

        if (typeof this.form?.submissionRules?.typeform === 'object') {
          this.typeformRules = {...this.form?.submissionRules?.typeform};
        }
      },
    },
  },
  methods: {
    addFieldMapping() {
      this.typeformRules.fieldMapping.push({
        pulseHealthField: null,
        typeformField: null,
      });

      this.updateSubmissionRules();
    },
    deleteFieldMapping(key) {
      this.$delete(this.typeformRules.fieldMapping, key);

      this.updateSubmissionRules();
    },
    getAvailablePulseHealthFields(pulseHealthField) {
      return this.pulseHealthFields.filter(field => {
        return field.value === pulseHealthField
          || !this.selectedPulseHealthFields.includes(field.value);
      });
    },
    async loadPageDetails() {
      const response = (await this.$rest.plugins.typeform.getFormPageDetails(this.form?.id ?? '')).data;

      this.forms = response.forms?.items.map(form => ({
        id: form.id,
        name: form.title,
      })) ?? [];
      this.loadedForm = response?.form;
      this.loadedWebhook = response?.webhook;

      if (!this.loadedForm && this.form?.submissionRules?.typeform?.formId) {
        this.configuredFormIsNotLoaded = true;
      }
    },
    async onFormChange(typeformFormId) {
      if (this.loadedForm?.id === typeformFormId) {
        return;
      }

      const typeform = (await this.$rest.plugins.typeform.getForm(typeformFormId)).data;

      this.loadedForm = typeform?.form;
      this.loadedWebhook = typeform?.webhook;

      this.typeformRules.formId = typeformFormId;

      this.updateSubmissionRules();
    },
    updateSubmissionRules() {
      this.$emit('update', {
        ...this.form,
        vendorCreativeID: this.typeformRules.formId ? this.typeformRules.formId : null,
        submissionRules: {
          ...this.form.submissionRules,
          typeform: this.typeformRules,
        },
      });
    },
  },
};
</script>
